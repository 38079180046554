import React from 'react';
import { Typography } from '@material-ui/core';

const Projects = () => {
  return (
    <Typography>
      Here will be list of mt own projects, soon...
    </Typography>
  );
};

export default Projects;