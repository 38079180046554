import React from 'react';
import { Typography } from '@material-ui/core';

const Blog = () => {
  return (
    <Typography>
      Here will be my blog, when I found some time to create blog engine.
    </Typography>
  );
};

export default Blog;